<template>
    <router-view v-wechat-title="`${$route.meta.title}${title?' - '+title:''}`"></router-view>
    <FullLoading v-if="loading"></FullLoading>
</template>
<script setup>
    import { ref, computed } from 'vue';
    import { getBasicConfiguration } from "@/api"
    import { sessionStorage } from '@/utils/storage'
    import { useStore } from 'vuex'
    const store = useStore();
    const title = computed(() => store.state.system.basics_title)
    const loading = ref(true)
    import FullLoading from '@/components/FullLoading/index.vue';

    //在页面加载时读取sessionStorage里的状态信息
    let storeInfo = sessionStorage.get('store')
    if (storeInfo) {
        store.replaceState(Object.assign({}, store.state, storeInfo))
        sessionStorage.remove('store')
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
        sessionStorage.set('store', store.state)
    })

    // 获取网站配置
    getBasicConfiguration().then(res => {
        if (res.code == 0) {
            store.commit('setSystemInfo', res.data)
            loading.value = false
        }
    }).catch(err => {
        loading.value = false
    })
</script>
<style lang="less">
    @import '@/assets/css/style.less';
    @import '//at.alicdn.com/t/c/font_3749482_40a5284mn1m.css';
    
</style>