
export default [
  
  
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/account/index.vue'),
    redirect: {
      name: 'account-login'
    },
    children: [{
      path: 'login',
      name: "account-login",
      component: () => import("@/views/account/login.vue"),
      meta: {
        title: '登录',
      }
    }, {
      path: 'register',
      name: "account-register",
      component: () => import("@/views/account/register.vue"),
      meta: {
        title: '注册',
      }
    }, {
      path: 'forget',
      name: "account-forget",
      component: () => import("@/views/account/forget.vue"),
      meta: {
        title: '忘记密码',
      }
    },
    ]
  },
  {
    path: '/pay/alipay',
    name: 'pay-alipay',
    meta: {
      title: '支付宝支付'
    },
    component: () => import('@/views/pay/alipay.vue'),
  },
  {
    path: '/pay/wxpay',
    name: 'pay-wxpay',
    meta: {
      title: '微信支付'
    },
    component: () => import('@/views/pay/wxpay.vue'),
  },
 

  {
    path: '/return',
    name: 'return',
    meta: {
      title: '支付成功'
    },
    component: () => import('@/views/result/return/index.vue')
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      title: '出错啦 Error'
    },
    component: () => import('@/views/result/error/index.vue')
  },
  {
    path: '/:w+',
    name: '404Page',
    redirect: '/error?code=404&title=404 Not Found&tip=抱歉，您访问的页面不存在',
  },

];
