
import request from '@/utils/request'
import { getBaseUrl } from "@/utils/index"



// 图片文件
export const uploadPictures = getBaseUrl() + '/System/uploadPictures'
// 文件上传
export const uploadFiles = getBaseUrl() + '/System/uploadFiles'
// 数字验证码
export const Captcha = getBaseUrl() + '/Login/captcha?'


// 登录
export function login(data: object) {
  return request({
    url: '/Login/index',
    method: 'post',
    data
  })
}

// 查询网站配置
// export function getToConfigure() {
//   return request({
//     url: '/Home/getToConfigure',
//     method: 'get',
//   })
// }

// 查询许可证列表
export function getLicenceManageList(params: object) {
  return request({
    url: '/LicenceManage/getLicenceManageList',
    method: 'get',
    params
  })
}
// 修改新增许可证
export function updateAddLicenceManage(data: object) {
  return request({
    url: '/LicenceManage/updateAddLicenceManage',
    method: 'put',
    data
  })
}
// 删除许可证
export function deleteLicenceManage(params: object) {
  return request({
    url: '/LicenceManage/deleteLicenceManage',
    method: 'delete',
    params
  })
}

// 查询域名列表
export function getDomainManageList(params: object) {
  return request({
    url: '/DomainManage/getDomainManageList',
    method: 'get',
    params
  })
}

// 修改新增域名
export function updateAddDomainManage(data: object) {
  return request({
    url: '/DomainManage/updateAddDomainManage',
    method: 'put',
    data
  })
}

// 删除域名
export function deleteDomainManage(params: object) {
  return request({
    url: '/DomainManage/deleteDomainManage',
    method: 'delete',
    params
  })
}

// 修改新增用户
export function updateAddUserManage(data: object) {
  return request({
    url: '/UserManage/updateAddUserManage',
    method: 'put',
    data
  })
}

// 查询用户列表
export function getUserManageList(params: object) {
  return request({
    url: '/UserManage/getUserManageList',
    method: 'get',
    params
  })
}
// 删除域名
export function deleteUserManage(params: object) {
  return request({
    url: '/UserManage/deleteUserManage',
    method: 'delete',
    params
  })
}

// 查询全部项目列表
export function getProjectManageAllList(params: object) {
  return request({
    url: '/ProjectManage/getProjectManageAllList',
    method: 'get',
    params
  })
}

// 查询管理项目列表
export function getProjectManageList(params: object) {
  return request({
    url: '/ProjectManage/getProjectManageList',
    method: 'get',
    params
  })
}

// 修改新增项目
export function updateSaveProjectManage(data: object) {
  return request({
    url: '/ProjectManage/updateSaveProjectManage',
    method: 'put',
    data
  })
}

// 删除产品
export function deleteProjectManage(params: object) {
  return request({
    url: '/ProjectManage/deleteProjectManage',
    method: 'delete',
    params
  })
}

// 搜索用户列表
export function getSearchUser(params: object) {
  return request({
    url: '/UserManage/getSearchUser',
    method: 'get',
    params
  })
}

// 查询管理版本列表
export function getVersionManageList(params: object) {
  return request({
    url: '/ProjectManage/getVersionManageList',
    method: 'get',
    params
  })
}

// 修改新增版本
export function updateSaveVersionManage(data: object) {
  return request({
    url: '/ProjectManage/updateSaveVersionManage',
    method: 'put',
    data
  })
}

// 删除版本
export function deleteVersionManage(params: object) {
  return request({
    url: '/ProjectManage/deleteVersionManage',
    method: 'delete',
    params
  })
}

// 获取设置信息
export function getTheQuerySet(type: string, params: object) {
  return request({
    url: '/System/getTheQuerySet/' + type,
    method: 'get',
    params
  })
}

// 保存设置
export function setSaveSettings(data: object) {
  return request({
    url: '/System/setSaveSettings',
    method: 'put',
    data
  })
}

// 获取产品信息
export function getProductInfo(params: object) {
  return request({
    url: '/Index/getProductInfo',
    method: 'get',
    params
  })
}

// 获取基本配置
export function getBasicConfiguration(params: object) {
  return request({
    url: '/Index/getBasicConfiguration',
    method: 'get',
    params
  })
}

// 获取我的许可证
export function getMyLicense(params: object) {
  return request({
    url: '/User/getMyLicense',
    method: 'get',
    params
  })
}

// 授权域名
export function authorizedDomainName(data: object) {
  return request({
    url: '/User/authorizedDomainName',
    method: 'post',
    data
  })
}

// 更换授权域名
export function replaceDomainName(data: object) {
  return request({
    url: '/User/replaceDomainName',
    method: 'post',
    data
  })
}

// 获取更换记录
export function getReplacementRecord(params: object) {
  return request({
    url: '/DomainManage/getReplacementRecord',
    method: 'get',
    params
  })
}

// 发送测试邮件
export function sendTestEmail() {
  return request({
    url: '/System/sendTestEmail',
    method: 'get',
  })
}
// 获取验证码
export function getVerificationCode(data: object) {
  return request({
    url: '/Login/getVerificationCode',
    method: 'post',
    data
  })
}
// 注册
export function register(data: object) {
  return request({
    url: '/Login/register',
    method: 'post',
    data
  })
}

// 找回密码
export function forgetPassword(data: object) {
  return request({
    url: '/Login/forgetPassword',
    method: 'post',
    data
  })
}
// 查询用户信息
export function getUserInformation(params: object) {
  return request({
    url: '/User/getUserInformation',
    method: 'get',
    params
  })
}
// 查询首页项目列表
export function getProductList() {
  return request({
    url: '/Index/getProductList',
    method: 'get',
  })
}
// 创建购买次数订单
export function createSubmitBuy(data: object) {
  return request({
    url: '/User/createSubmitBuy',
    method: 'post',
    data
  })
}
// 支付
export function submitPayment(data: object) {
  return request({
    url: '/User/submitPayment',
    method: 'post',
    data
  })
}
// 订单管理列表
export function getOrderManageList(params: object) {
  return request({
    url: '/OrderManage/getOrderManageList',
    method: 'get',
    params
  })
}
// 修改订单
export function updateOrderManage(data: object) {
  return request({
    url: '/OrderManage/updateOrderManage',
    method: 'put',
    data
  })
}
// 删除订单
export function deleteOrderManage(params: object) {
  return request({
    url: '/OrderManage/deleteOrderManage',
    method: 'delete',
    params
  })
}
// 查询用户列表
export function getMyOrder(params: object) {
  return request({
    url: '/User/getMyOrder',
    method: 'get',
    params
  })
}
// 修改密码
export function updateChangePassword(data: object) {
  return request({
    url: '/User/updateChangePassword',
    method: 'put',
    data
  })
}

// 获取登陆日志
export function getLoginLog(params: object) {
  return request({
    url: '/User/getLoginLog',
    method: 'get',
    params
  })
}

// 获取邮箱验证码
export function getEmailVerification(data: object) {
  return request({
    url: '/User/getEmailVerification',
    method: 'post',
    data
  })
}

// 获取短信验证码
export function getPhoneVerification(data: object) {
  return request({
    url: '/User/getPhoneVerification',
    method: 'post',
    data
  })
}

// 修改邮箱
export function updateChangeEmail(data: object) {
  return request({
    url: '/User/updateChangeEmail',
    method: 'put',
    data
  })
}

// 修改信息
export function updateChangeInfo(data: object) {
  return request({
    url: '/User/updateChangeInfo',
    method: 'put',
    data
  })
}

// 修改手机号
export function updateChangePhone(data: object) {
  return request({
    url: '/User/updateChangePhone',
    method: 'put',
    data
  })
}

// 获取服务条款
export function getTermsOfService(params: object) {
  return request({
    url: '/Index/getTermsOfService',
    method: 'get',
    params
  })
}

// 获取首页用户中心
export function getMyHome(params: object) {
  return request({
    url: '/User/getMyHome',
    method: 'get',
    params
  })
}

// 获取控制台信息
export function getHomeInfo(params: object) {
  return request({
    url: '/Home/getHomeInfo',
    method: 'get',
    params
  })
}

// 获取控制台统计
export function getHomeStatistics(params: object) {
  return request({
    url: '/Home/getHomeStatistics',
    method: 'get',
    params
  })
}

// 获取订单统计
export function getOrderStatistics(params: object) {
  return request({
    url: '/OrderManage/getOrderStatistics',
    method: 'get',
    params
  })
}

// 获取授权说明
export function getAuthDescription(params: object) {
  return request({
    url: '/User/getAuthDescription',
    method: 'get',
    params
  })
}

// 获取订单状态
export function getOrderStatus(params: object) {
  return request({
    url: '/User/getOrderStatus',
    method: 'get',
    params
  })
}

// 正版查询
export function viewGenuineQuery(data: object) {
  return request({
    url: '/Index/viewGenuineQuery',
    method: 'post',
    data
  })
}

// 下载安装包
export function downloadPackageManage(params: object) {
  return request({
    url: '/ProjectManage/downloadPackageManage',
    method: 'get',
    responseType: 'blob',
    params
  })
}

// 下载安装包
export function downloadPackageUser(params: object) {
  return request({
    url: '/User/downloadPackageUser',
    method: 'get',
    responseType: 'blob',
    params
  })
}

// 获取下载应用列表
export function getApplicationList(params: object) {
  return request({
    url: '/User/getApplicationList',
    method: 'get',
    params
  })
}

// 获取更新日志
export function getUpdateLog(params: object) {
  return request({
    url: '/Index/getUpdateLog',
    method: 'get',
    params
  })
}















