import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import defaultRouter from './modules/default';
import manageRouter from './modules/manage';
import indexRouter from './modules/index';
import userRouter from './modules/user';

// 关于单层路由，meta 中设置 { single: true } 即可为单层路由，{ hidden: true } 即可在侧边栏隐藏该路由

// 存放动态路由
export const asyncRouterList: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import("@/views/layout/index.vue"),
    children: [
      ...manageRouter,
    ]
  },

];

// 存放固定的路由
const defaultRouterList: Array<RouteRecordRaw> = [
  ...defaultRouter,
  ...indexRouter,
  ...userRouter
]


export const allRoutes = [...defaultRouterList, ...asyncRouterList];
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes: allRoutes,
})


/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach((to, from, next) => {
  // 判断是否需要登录才可以进入
  if (to.matched.some(_ => _.meta.auth)) {
    // 这里依据 token 判断是否登录，可视情况修改
    const token = localStorage.getItem('Geek-Token') ? localStorage.getItem('Geek-Token') : sessionStorage.getItem('Geek-Token')

    if (token && token !== 'undefined') {
      next();
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next('/account/login?redirect='+to.fullPath)
    }
  } else {
    // 不需要身份校验 直接通过
    next();
  }
});


export default router
