/**
 * Geek-Auth 基础业务配置
 * */

export const Setting = {
   // 请求头相关
   Authorization: "V3 4ZlJmYbKt09iYs8j4cxf3xGKAxWdezGi",
   XTimestamp: Date.parse(new Date().toString()),

   // 用户菜单列表 - 登录前
   UserMenuBefore: [
      { content: "登录", value: "/account/login" },
      { content: "注册", path: "/account/register" },
      { content: "正版查询", path: "/loginLog" },
   ],
   // 用户菜单列表 - 登录后
   UserMenuAfter: [
      { content: "用户中心", icon: "gm auth-huiyuanzhongxin", value: "/user/home" },
      { content: "个人信息", icon: "gm auth-geren", value: "/user/personal" },
      { content: "登录日志", icon: "gm auth-rizhi", value: "/user/loginLog" },
      { content: "退出登录", icon: "gm auth-tuichu", value: "/account/login" },
   ],
   // 主题
   ThemeOptions:[
      {label:"主题1",value:"--linear-bg-1"},
      {label:"主题2",value:"--linear-bg-2"},
      {label:"主题3",value:"--linear-bg-3"},
      {label:"主题4",value:"--linear-bg-4"},
      {label:"主题5",value:"--linear-bg-5"},
      {label:"主题6",value:"--linear-bg-6"},
      {label:"主题7",value:"--linear-bg-7"},
      {label:"主题8",value:"--linear-bg-8"},
      {label:"主题9",value:"--linear-bg-9"},
      {label:"主题10",value:"--linear-bg-10"},
      {label:"主题11",value:"--linear-bg-1m"},
      {label:"主题12",value:"--linear-bg-2m"},
      {label:"主题13",value:"--linear-bg-3m"},
      {label:"主题14",value:"--linear-bg-4m"},
      {label:"主题15",value:"--linear-bg-5m"},
      {label:"主题16",value:"--linear-bg-6m"},
      {label:"主题17",value:"--linear-bg-7m"},
      {label:"主题18",value:"--linear-bg-8m"},
      {label:"主题19",value:"--linear-bg-9m"},
      {label:"主题20",value:"--linear-bg-10m"},
   ],




};
