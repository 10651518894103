
const meta = {
  auth: true
};
export default [
  {
    path: '/',
    name: "index",
    component: () => import("@/views/index/index.vue"),
    meta: {
      title: '首页',
    }
  },
  {
    path: '/p',
    name: 'p',
    component: () => import("@/views/index/main.vue"),
    children: [
      {
        path: ':name',
        name: "p-name",
        component: () => import("@/views/index/details.vue"),
        meta: {
          title: '产品',
        }
      },
      {
        path: ':name/buy',
        name: "p-buy",
        component: () => import("@/views/index/buy.vue"),
        meta: {
          ...meta,
          title: '订单支付',
        }
      },
      {
        path: 'clause',
        name: "p-clause",
        component: () => import("@/views/index/clause.vue"),
        meta: {
          title: '用户条款',
        }
      },
      {
        path: 'query',
        name: "p-query",
        component: () => import("@/views/index/query.vue"),
        meta: {
          title: '正版查询',
        }
      },
      {
        path: ':name/update-log',
        name: "p-update-log",
        component: () => import("@/views/index/update-log.vue"),
        meta: {
          ...meta,
          title: '更新日志',
        }
      },
      
      
    ]
  },



]