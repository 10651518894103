import axios from 'axios'
import router from '@/router'
import { Setting } from "./setting"
import {getBaseUrl} from "./index"
import { MessagePlugin } from 'tdesign-vue-next';
const service = axios.create({
  baseURL: getBaseUrl(),
  timeout: 100000,
  withCredentials: true,
  // withCredentials状态下 后端要设置Access-Control-Allow-Origin为你的源地址 不能为通配符
})
// http request 请求拦截器
service.interceptors.request.use(
  (config) => {
    (config.headers as any)['Content-Type'] = 'application/json;charset=UTF-8';
    (config.headers as any)['Authorization'] = Setting.Authorization;
    (config.headers as any)['X-Timestamp'] = Setting.XTimestamp;
    (config.headers as any)['Env'] = process.env.NODE_ENV;
    const AccessToken = localStorage.getItem('Geek-Token') ? localStorage.getItem('Geek-Token') : sessionStorage.getItem('Geek-Token')
    if (AccessToken) {
      (config.headers as any)['Token'] = AccessToken;
    }
    return config
  },
  (error) => {
    MessagePlugin.error('请求失败');
    return Promise.reject(error)
  }
)


// http response 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data

    if (res.code == -2) { //登陆失效
      localStorage.removeItem("Geek-Token");
      sessionStorage.removeItem("Geek-Token")
      router.push('/account');
      return
    }
    if (res.code == -3) { //非法请求
      router.push(`/error?title=${res.msg}&tip=抱歉，您访问出现异常`);
      return
    }
    
    return res;
  },
  (err) => {
    if (err && err.response && err.response.status) {
      switch (err.response.status) {
        case 400:
          err.message = "错误请求";
          break;
        case 401:
          err.message = "未授权，请重新登录";
          break;
        case 403:
          err.message = "拒绝访问，请重新登录";
          break;
        case 404:
          err.message = "请求错误,未找到该资源";
          break;
        case 405:
          err.message = "请求方法未允许";
          break;
        case 408:
          err.message = "请求超时";
          break;
        case 500:
          err.message = "服务器端出错";
          break;
        case 501:
          err.message = "网络未实现";
          break;
        case 502:
          err.message = "网络错误";
          break;
        case 503:
          err.message = "服务不可用";
          break;
        case 504:
          err.message = "网络超时";
          break;
        case 505:
          err.message = "http版本不支持该请求";
          break;
        default:
          err.message = "连接错误";
      }
      let tip = err.response.data.message?err.response.data.message:'访问出错啦！错误码：'+err.response.status
      router.push(`/error?code=${err.response.status}&title=${err.message}&tip=${tip}`);
    }

    return Promise.reject(err)
  }
);




export default service