
const meta = {
  auth: true
};
export default [
  {
    path: '/user',
    name: "user",
    component: () => import("@/views/user/main.vue"),
    children: [
      {
        path: 'home',
        name: "user-home",
        component: () => import("@/views/user/home.vue"),
        meta: {
          ...meta,
          title: '首页',
          icon: 'gm auth-shouye',
        }
      },
      {
        path: 'licence',
        name: "user-licence",
        component: () => import("@/views/user/licence.vue"),
        meta: {
          ...meta,
          title: '我的授权',
          icon: 'gm auth-icon_wangye',
        }
      },
      {
        path: 'download',
        name: "user-download",
        component: () => import("@/views/user/download.vue"),
        meta: {
          ...meta,
          title: '下载程序',
          icon: 'gm auth-yunduanxiazai',
        }
      },
      {
        path: 'order',
        name: "user-order",
        component: () => import("@/views/user/order.vue"),
        meta: {
          ...meta,
          title: '我的订单',
          icon: 'gm auth-icon_renwujincheng',
        }
      },
      {
        path: 'extension',
        name: "user-extension",
        component: () => import("@/views/user/extension.vue"),
        meta: {
          ...meta,
          title: '推广中心',
          icon: 'gm auth-wode-tuijianyouli',
        }
      },
      // {
      //   path: 'market',
      //   name: "user-market",
      //   component: () => import("@/views/user/market.vue"),
      //   meta: {
      //     ...meta,
      //     title: '应用市场',
      //     icon: 'gm auth-yingyong',
      //   }
      // },
      {
        path: 'changePassword',
        name: "user-changePassword",
        component: () => import("@/views/user/changePassword.vue"),
        meta: {
          ...meta,
          title: '修改密码',
          hidden: true
        }
      },
      {
        path: 'loginLog',
        name: 'user-loginLog',
        component: () => import("@/views/user/loginLog.vue"),
        meta: {
          ...meta,
          title: '登录日志',
          hidden: true
        }
      },
      {
        path: 'personal',
        name: 'user-personal',
        component: () => import("@/views/user/personal.vue"),
        meta: {
          ...meta,
          title: '个人信息',
          hidden: true
        }
      },
      {
        path: 'changeEmail',
        name: 'user-changeEmail',
        component: () => import("@/views/user/changeEmail.vue"),
        meta: {
          ...meta,
          title: '修改邮箱',
          hidden: true
        }
      },
      {
        path: 'changePhone',
        name: 'user-changePhone',
        component: () => import("@/views/user/changePhone.vue"),
        meta: {
          ...meta,
          title: '修改手机号',
          hidden: true
        }
      },
      
    ]
  },



]