
const meta = {
  auth: true
};
export default [
  {
    path: '/manage/home',
    name: "manage-home",
    component: () => import("@/views/manage/home.vue"),
    meta: {
      ...meta,
      title: '控制台',
      icon: 'gm auth-shujukanban',
    }
  },
  {
    path: '/manage/order',
    name: "manage-order",
    component: () => import("@/views/manage/order.vue"),
    meta: {
      ...meta,
      title: '订单管理',
      icon: 'gm auth-icon_renwujincheng',
    }
  },
  {
    path: '/manage/user',
    name: "manage-user",
    component: () => import("@/views/manage/user.vue"),
    meta: {
      ...meta,
      title: '用户管理',
      icon: 'gm auth-yonghuguanli',
    }
  },
  {
    path: '/manage/licence',
    name: "manage-licence",
    component: () => import("@/views/manage/licence.vue"),
    meta: {
      ...meta,
      title: '许可证管理',
      icon: 'gm auth-icon_wangye',
    }
  },
  {
    path: '/manage/domain',
    name: "manage-domain",
    component: () => import("@/views/manage/domain.vue"),
    meta: {
      ...meta,
      title: '域名管理',
      icon: 'gm auth-wumoxing',
    }
  },
  {
    path: '/manage/project',
    name: "manage-project",
    component: () => import("@/views/manage/project.vue"),
    meta: {
      ...meta,
      title: '产品管理',
      icon: 'gm auth-yingyongguanli',
    }
  },
  {
    path: '/manage/project/save',
    name: "manage-project-save",
    component: () => import("@/views/manage/project-update.vue"),
    meta: {
      ...meta,
      title: '新增产品',
      hidden: true
    }
  },
  {
    path: '/manage/project/update',
    name: "manage-project-update",
    component: () => import("@/views/manage/project-update.vue"),
    meta: {
      ...meta,
      title: '编辑产品',
      hidden: true
    }
  },
  {
    path: '/manage/project/releases',
    name: "manage-project-releases",
    component: () => import("@/views/manage/project-releases.vue"),
    meta: {
      ...meta,
      title: '版本管理',
      hidden: true
    }
  },
  {
    path: '/manage/project/api',
    name: "manage-project-api",
    component: () => import("@/views/manage/project-api.vue"),
    meta: {
      ...meta,
      title: '项目接口',
      hidden: true
    }
  },
  {
    path: '/manage/system',
    name: "manage-system",
    component: () => import("@/views/system/index.vue"),
    meta: {
      ...meta,
      title: '系统设置',
      icon: 'gm auth-icon_shezhi',
    }
  },
  

]