/**
 * 常用方法封装
 * @time 2022.06.10
 */
import router from '@/router'
import store from '@/store'

/**
 * 获取环境请求路径
 */
export function getBaseUrl() {
  if (process.env.NODE_ENV === 'production') {
    // js使用window.CONFIG.url
    return (window as any).CONFIG.url
  } else {
    return process.env.VUE_APP_API_BASE_URL
  }
}


// 支付处理
export function payCallback(data: any) {
  console.log(data)
  if (data.order.payment == 'wxpay') {
    // 微信支付
    if (data.type == 1) {
      if (data.detail.trade_type == 'native') {
        store.commit('setOrderInfo', data)
        router.push('/pay/wxpay');
      }
      if (data.detail.trade_type == 'jsapi') {
        store.commit('setOrderInfo', data)
        router.push('/pay/wxpay');
      }
      if (data.detail.trade_type == 'h5') {
        var a = document.createElement("a");
        (a as any)["alipaysubmit"].setAttribute("href", data.detail.h5_url);
        // a.setAttribute("target", '_blank');
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
      }
    }
    // 易支付
    if (data.type == 3) {
      const div = document.createElement("div");
      div.innerHTML = data.detail;
      document.body.append(div);
      // (document.forms as any)["alipaysubmit"].setAttribute('target', '_blank');
      (document.forms as any)["alipaysubmit"].submit();
    }
  }

  if (data.order.payment == 'alipay') {
    if (data.type == 1) {
      const div = document.createElement("div");
      div.innerHTML = data.detail;
      document.body.append(div);
      // (document.forms as any)["alipaysubmit"].setAttribute('target', '_blank');
      (document.forms as any)["alipaysubmit"].submit();
    }
    // 当面付
    if (data.type == 2) {
      store.commit('setOrderInfo', data)
      router.push('/pay/alipay');
    }
    // 易支付
    if (data.type == 3) {
      const div = document.createElement("div");
      div.innerHTML = data.detail;
      document.body.append(div);
      // (document.forms as any)["alipaysubmit"].setAttribute('target', '_blank');
      (document.forms as any)["alipaysubmit"].submit();
    }
  }
}


/**
 * 获取当前时间段
 */
export function getTimeState() {
  // 获取当前时间
  let timeNow = new Date();
  // 获取当前小时
  let hours = timeNow.getHours();
  // 设置默认文字
  let state = ``;
  // 判断当前时间段
  if (hours >= 0 && hours <= 10) {
    state = `早上好！`;
  } else if (hours > 10 && hours <= 14) {
    state = `中午好！`;
  } else if (hours > 14 && hours <= 18) {
    state = `下午好！`;
  } else if (hours > 18 && hours <= 24) {
    state = `晚上好！`;
  }
  return state;
}

/**
 * 判断是否微信浏览器
 */
export function isWeixin() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
}

/**
 * 获取URL参数
 */
export function getUrlString(name: string) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substring(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}


// 重定向获取code
export function requestToSendWechat() {
  var local = encodeURIComponent(window.location.href);
  var appid = store.state.system.appid;
  window.location.replace('http://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + local + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect')
  // window.location.href = 'http://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + local + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
}

/**
 * 去除前后空格
 * @param str 字符串
 * @returns 
 */
export function trim(str: string) {
  return String.prototype.trim.call(str);
}