import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';


//动态修改title
import VueWechatTitle from 'vue-wechat-title'
// 图片懒加载
// import lazyPlugin from 'vue3-lazy'


import Empty from '@/components/Empty.vue';



const app = createApp(App);
app.config.globalProperties.$CONFIG = (window as any).CONFIG;

// app.use(lazyPlugin, {
//     loading: require('@/assets/images/lazy-loading.gif'), // 图片加载时默认图片
//     error: require('@/assets/images/lazy-error.png')// 图片加载失败时默认图片
// })
app.component('Empty', Empty);
app.use(TDesign);
app.use(VueWechatTitle);
app.use(store);
app.use(router);
app.mount('#app');