import { createStore } from 'vuex'

export default createStore({
  state: {
    projectInfo: {},
    system: {appid:""},
    userInfo: { id: "" },
    orderInfo:{},

  },
  getters: {
  },
  mutations: {
    // 存储项目信息
    setProjectInfo(state, param) {
      state.projectInfo = param
    },
    // 存储配置信息
    setSystemInfo(state, param) {
      state.system = param;
    },
    // 存储用户信息
    setUserInfo(state, param) {
      state.userInfo = param;
    },
    // 退出登录
    logOutFun(state) {
      localStorage.removeItem("Geek-Token");
      sessionStorage.removeItem("Geek-Token")
      state.userInfo = { id: "" }
    },
    // 存储订单
    setOrderInfo(state, param) {
      state.orderInfo = param;
    },



  },
  actions: {
  },
  modules: {
  }
})
